<template>
  <section>  
    <b-row class="match-height">
      <div class="col-md-12 col-lg-12">
        <div class="card">
          <div class="card-header border-bottom">
            <h4 class="card-title">
              Brands ({{ brands.length }})
            </h4>
            <form>
              <div class="form-group">
                <input
                  v-model="searchParam"
                  name="search"
                  placeholder="Search"
                  class="form-control"
                >
              </div>
            </form>
            <b-button
              v-b-modal.event-modal
              variant="primary"
            >
              Create Brand
            </b-button>
          </div>
          <div class="card-datatable">
            <div
              v-if="dataLoading"
              class="overlay"
            >
              Please wait ....
            </div>            
            <table v-else class="datatables-ajax table table-responsive-lg w-100 ">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Cover</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>SN</th>
                  <th>Cover</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </tfoot>
              <tbody>
                <tr
                  v-for="(brand, index) in brandList"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    <b-img 
                      :src="brand.logo"
                      width="70"
                    />
                  </td>
                  <td>
                    {{ brand.name }}
                  </td>
                  <td>{{ brand.description }}</td>
                  <td>{{ brand.status }}</td>
                  <td>
                    <b-button-group>
                      <b-button
                        v-b-modal.event-edit-modal
                        variant="primary"
                        size="sm"
                        @click="selectBrand(brand)"
                      >
                      <feather-icon
                        class="mr-25"
                        icon="EditIcon"
                        size="15"
                      />
                      </b-button>
                      <b-button 
                        variant="danger" 
                        size="sm"
                        @click="deleteBrand(brand)"
                      >
                      <feather-icon
                        class="mr-25"
                        icon="DeleteIcon"
                        size="15"
                      />
                      </b-button>
                    </b-button-group>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-row> 
      <nav aria-label="Page navigation">
        <ul class="pagination">
          <li :class="link.active ? 'page-item active' : 'page-item'" v-for="link in meta.links" :key="link.id">
            <a class="page-link" href="javascript:;" @click="paginate(link.url)" v-html="link.label"></a>
          </li>
        </ul>
      </nav>     
    <b-modal
      id="event-modal"
      content-class="shadow"
      title="Add New Brand"
      centered
      size="lg"
      no-close-on-backdrop
      hide-footer
    >
      <b-form>
        <b-row>
          <b-col lg="12" md="12">
            <b-form-group
              class="text-primary"
              label="Name"
            >
              <b-form-input
                type="text"
                name="name"
                aria-label="Name"
                v-model="details.name"
                placeholder="Name"
              />
            </b-form-group>
          </b-col>

          <b-col lg="12" md="12">
            <b-form-group
              class="text-primary"
              label="Description"
            >
              <b-form-textarea
                name="body"
                aria-label="Description"
                v-model="details.description"
                placeholder="Description"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Status"
            >
              <b-form-radio-group
                v-model="details.status"
              >
                <b-form-radio value="Published" /> 
                Publish
                <b-form-radio value="Unpublished" /> 
                Draft
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Logo"
            >
              <b-form-file
                @change="prepareFile"
                accept=".jpg, .png, .gif"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-form-group>
          </b-col>
          <b-col md="12" lg="12" class="text-center">
            <b-button variant="primary" size="lg" @click="addBrand">
              Save 
              <b-spinner
                variant="white"
                small
                class="ml-1"
                v-show="loading"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>       
    <b-modal
      id="event-edit-modal"
      content-class="shadow"
      :title="`Edit ${selected.title}`"
      centered
      size="lg"
      no-close-on-backdrop
      hide-footer
    >
      <b-form>
        <b-row>
          <b-col lg="12" md="12">
            <b-form-group
              class="text-primary"
              label="Name"
            >
              <b-form-input
                type="text"
                name="name"
                aria-label="name"
                v-model="selected.name"
                placeholder="Name"
              />
            </b-form-group>
          </b-col>

          <b-col lg="12" md="12">
            <b-form-group
              class="text-primary"
              label="Description"
            >
              <b-form-textarea
                name="body"
                aria-label="Description"
                v-model="selected.description"
                placeholder="Description"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Status"
            >
              <b-form-radio-group
                v-model="selected.status"
              >
                <b-form-radio value="Published" /> 
                Publish
                <b-form-radio value="Unpublished" /> 
                Draft
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-img 
              :src="selected.logo"
              width="100"
            />
            <b-form-group
              class="text-primary"
              label="Change logo"
            >
              <b-form-file
                @change="prepareFile"
                accept=".jpg, .png, .gif"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-form-group>
          </b-col>
          <b-col md="12" lg="12" class="text-center">
            <b-button variant="primary" size="lg" @click="updateBrand">
              Save Changes
              <b-spinner
                variant="white"
                small
                class="ml-1"
                v-show="loading"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>       
  </section>
</template>

<script>
import {
  BRow, BMedia, BAvatar, BCard, BCol, BCardBody, 
  BMediaAside, BMediaBody,BCardText, BButtonGroup,
  BButton, BModal, BForm, BFormGroup, BImg, BFormRadioGroup, BFormRadio,
  BFormDatepicker, BFormFile,BFormInput, BFormTextarea, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BSpinner,
    BImg,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,
    BFormInput,
    BFormFile,
    BFormDatepicker,
    BButtonGroup,
    BForm,
    BFormGroup,
    BModal,
    BButton,
    BMedia,
    BAvatar,
    BCard,
    BCol,
    BCardBody, BMediaAside, BMediaBody,BCardText,
  },
  data() {
    return {
      form: new FormData(),
      dataLoading: false,
      details: {},
      selected: {},
      data: {},
      brands: [],
      meta: {},
      links: {},
      url: '',
      loading: false,
      searchParam: '',
    }
  },
  computed: {
    brandList() {
      return this.brands.filter(item => item.name.includes(this.searchParam) || item.name.toLowerCase().includes(this.searchParam))
    },
  },
  mounted() {
    this.url = this.$url+'/brands'
    this.getBrands()
  },
  methods: {
    prepareFile(event) {
      const files = event.target.files || event.dataTransfer.files
      if (!files.length) return
      this.form.append('logo', files[0])
    }, 
    deleteBrand(item) {
      this.$swal({
        title: `Delete ${item.name}`,
        html: '<div class=" text-center mt-4"><h4 class="text-danger mb-3">Are you sure you want to delete this ?</h4></div>',
        confirmButtonText: 'Yes, delete it!',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary text-primary ml-1',
        },        
      })
      .then(result => {
        if (result.value) {
          this.deleteSelected(item.id)
        } 
      })
    },    
    deleteSelected(id) {
      this.$http.delete(`${this.$url}/brands/${id}`)
        .then(response => {
          if(response.data.status) {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            .then(res => {
              window.location.reload()
            })
          }
        })
        .catch( err => {
          if(err.response) {
            let message
            if(err.response.status != 200) {
              if(err.response.data.errors) {
                let errors = err.response.data.errors
                let errorList = Object.values(errors)
                errorList.map(msg => {
                    message = msg
                })
              }
              this.$swal({
                title: 'Error',
                text: err.response.data.message || message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-secondary',
                },
              })  
            }        
          }          

        })
    },     
    selectBrand(item) {
      this.selected = item
    },   
    paginate(url) {
      this.url = url
      this.getEvents()
    },  
    updateBrand() {
      this.loading = true
      this.form.append("name", this.selected.name)
      this.form.append("description", this.selected.description)
      this.form.append("status", this.selected.status)
      this.$http.post(`${this.$url}/brands/${this.selected.id}`, this.form)
        .then(response => {
          if(response.data.status) {
            this.selected = response.data.data 
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })            
          }
        })
        .catch(err => console.log(err))
        .finally(() => this.loading = false)
    },        
    addBrand() {
      this.loading = true
      this.form.append("name", this.details.name)
      this.form.append("description", this.details.description)
      this.form.append("status", this.details.status)
      this.$http.post(`${this.$url}/brands`, this.form)
        .then(response => {
          if(response.data.status) {
            this.brands.push(response.data.data) 
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })            
          }
        })
        .catch(err => console.log(err))
        .finally(() => this.loading = false)
    },        
    getBrands() {
      this.dataLoading = true
      this.$http.get(`${this.url}`)
        .then(response => {
          if(response.data.status) {
            this.brands = response.data.data.data
            this.links = response.data.data.links
            this.meta = response.data.data.meta ?? []
          }
        })
        .finally(() => this.dataLoading = false)
    },
  },
}
</script>

<style lang="scss">
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,.2);
    font-size: 20px;
    color: #fff;
  }
</style>
